// @flow

const Status = {
  new: 'new',
  noConflict: 'noConflict',
  conflict: 'conflict',
  resolved: 'resolved'
};

export default { };

export {
  Status
};
